import { useState } from 'react';

import TradingTable from './TradingTable';

let cryptoListForex = {
    pairs: ['EURUSD=X', 'JPYUSD=X', 'GBPUSD=X', 'CHFUSD=X', 'AUDUSD=X', 'CADUSD=X', 'NZDUSD=X', 'CNYUSD=X', 'USDBRL=X', 'USDPLN=X'],
    images: ['EURUSD.png', 'JPYUSD.png', 'GBPUSD.png', 'CHFUSD.png', 'AUDUSD.png', 'CADUSD.png', 'NZDUSD.png', 'CNYUSD.png', 'USDBRL.png', 'USDPLN.png'],
    names: ['EURUSD', 'JPYUSD', 'GBPUSD', 'CHFUSD', 'AUDUSD', 'CADUSD', 'NZDUSD', 'CNYUSD', 'USDBRL', 'USDPLN']
}
let cryptoListIndices = {
    pairs: ['^GSPC', 'NQ=F', 'DAX', '^DJI', '^N225', '^FTSE', '^HSI', 'RTY=F', '^FCHI'],
    images: ['SP500.png', 'NASDAQ100.png', 'DAX.png', 'DJI.png', 'Nikkei225.png', 'FTSE100.png', 'HSI.png', 'US2000.png', 'CAC.png'],
    names: ['SP500', 'NDQ100', 'DAX', 'DJI', 'JPN225', 'FTSE100', 'HSI', 'US2000', 'CAC40']
}
let cryptoListStocks = {
    pairs: ['AAPL', 'NVDA', 'GOOGL', 'TSLA', 'INTC', 'F', 'AMZN', 'META', 'MSFT', 'MU'],
    images: ['APPLE.png', 'Nvidia.png', 'GOOGL (Alphabet Inc (Google) Class A).png', 'Nvidia-1.png', 'INTC (Intel Corporation).png', 'f (Ford Motor Company).png', 'AMZN.png', 'META (Meta Platforms).png', 'MSFT (Microsoft Corp.).png', 'MU (Micron Technology).png'],
    names: ['AAPL', 'NVDA', 'GOOGL', 'TSLA', 'INTC', 'FORD', 'AMZN', 'META', 'MSFT', 'MU'],
}
let cryptoListCrypto = {
    pairs: ['BTC-USD', 'ETH-USD', 'BNB-USD', 'SOL-USD', 'XRP-USD', 'DOGE-USD', 'TON-USD', 'ADA-USD', 'DASH-USD'],
    images: ['none.png'],
    names: ['BTC', 'ETH', 'BNB', 'SOL', 'XRP', 'DOGE', 'TON', 'ADA', 'DASH']
}
let cryptoListCommodities = {
    pairs: ['GC=F', 'NG=F', 'ZC=F', 'BZ=F', 'SI=F', 'ZWT=F', 'KC=F', 'CT=F', 'HG=F'],
    images: ['XAUUSD.png', 'NGAS (Natural gas).png', 'CORN.png', 'Brent (Brent Crude Oil).png', 'XAGUSD (Silver).png', 'Wheat (Wheat Cash Contract).png', 'Coffee - (Coffee Cash Contract).png', 'Cotton (Cotton Cash Contract).png', 'cooper.png'],
    names: ['XAU', 'NGAS', 'CORN', 'XBR', 'XAG', 'WHT', 'CFE', 'CTN', 'HG']
}

// let cryptoListInfo = {
//     title: ['Forex', 'Indices', 'Commodities', 'Stocks', 'Crypto', ],
//     subtext: [
//         'Trade your favourite products from around the world',
//         'Open positions and diversify your portfolio with global indices.',
//         'Trade soft commodities or agricultural goods, such as sugar, coffee, cotton and more.',
//         'Choose from the hundreds of individual stocks available in the US and more.',
//         '',
//         '',
//     ],
//     text: [
//         'Access 40+ major, minor, and exotic forex pairs with low spreads from 0.0. Start trading the world’s leading currencies including EUR/USD, GBP/USD, and USD/JPY.',
//         'Leverage on the volatility of the largest stock market indices in the world like SP500, DAX, NAS100, and DJ30. Try online indices CFD trading with Vantage today.',
//         'Soft commodities refer to commodities that are grown rather than mined. When trading soft commodities CFDs, traders will have to consider factors including weather conditions, global demand, and geopolitical events that impact their production.',
//         'You can invest in the likes of Tesla, Netflix, Meta, and other listed companies through shares CFD. Get access to international share markets including US, Europe, Australia and Asia.',
//         'Cryptocurrencies (Crypto) are virtual currencies that typically use a decentralised network to carry out secure financial transactions. With Al Ansari Securities trading platform you can trade Crypto CFDs – such as Bitcoin, Ethereum and more – by speculating on their price movements, without actually owning them.',
//     ]
// }
let cryptoListInfo = {
    title: ['الفوركس', 'المؤشرات', 'السلع', 'الأسهم', 'العملات المشفرة', ],
    subtext: [
        'تداول منتجاتك المفضلة من جميع أنحاء العالم',
        'افتح صفقات ونوّع محفظتك مع المؤشرات العالمية.',
        'تداول السلع اللينة أو السلع الزراعية، مثل السكر والقهوة والقطن وغيرها.',
        'اختر من بين مئات الأسهم الفردية المتاحة في الولايات المتحدة وغيرها.',
        '',
        '',
    ],
    text: [
        'تمتع بالوصول إلى أكثر من 40 زوجًا من أزواج الفوركس الرئيسية والثانوية والغريبة مع فروق أسعار منخفضة تبدأ من 0.0. ابدأ تداول العملات الرائدة في العالم بما في ذلك اليورو/الدولار الأمريكي والجنيه الإسترليني/الدولار الأمريكي والدولار الأمريكي/الين الياباني.',
        'استفد من تقلبات أكبر مؤشرات سوق الأسهم في العالم مثل SP500 وداكس وناس 100 وDJ30. جرب تداول عقود الفروقات على المؤشرات عبر الإنترنت مع Vantage اليوم.',
        'تشير السلع اللينة إلى السلع التي تُزرع بدلًا من تعدينها. عند تداول العقود مقابل الفروقات على السلع اللينة، سيتعين على المتداولين مراعاة عوامل تشمل الظروف الجوية والطلب العالمي والأحداث الجيوسياسية التي تؤثر على إنتاجها.',
        'يمكنك الاستثمار في أسهم مثل Tesla وNetflix وMeta وغيرها من الشركات المُدرجة من خلال عقود الفروقات على الأسهم. احصل على إمكانية الوصول إلى أسواق الأسهم الدولية بما في ذلك الولايات المتحدة وأوروبا وأستراليا وآسيا.',
        'العملات الرقمية المشفرة (Crypto) هي عملات افتراضية تستخدم عادةً شبكة لا مركزية لإجراء معاملات مالية آمنة. من خلال منصة الأنصاري للأوراق المالية يمكنك تداول عقود الفروقات على العملات الرقمية المشفرة - مثل البيتكوين والإيثيريوم وغيرهما - من خلال المضاربة على تحركات أسعارها، دون امتلاكها فعليًا.',
    ]
}

export default function Chart(params) {
    const [selectedTable, setSelectedTable] = useState(cryptoListForex);
    const [selectedInfo, setSelectedInfo] = useState(cryptoListInfo);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const tr = {
        Forex: 'الفوركس', //Forex
        Indices: 'المؤشرات', //Indices
        Commodities: 'السلع', //Commodities
        Stocks: 'أسهم العقود مقابل الفروقات', //Stocks
        Crypto: 'صناديق الاستثمار المتداولة', //Crypto
        tableSubtext: '* يتم الإشارة إلى ساعات الافتتاح والإغلاق بالتوقيت العالمي المنسق (UTC +0)', //* opening and closing hours are indicated by UTC +0
        mainButtonText: 'اعرف المزيد',
    };

    const tables = [
        { list: cryptoListForex, label: tr?.Forex, index: 0 },
        { list: cryptoListIndices, label: tr?.Indices, index: 1 },
        { list: cryptoListCommodities, label: tr?.Commodities, index: 2 },
        { list: cryptoListStocks, label: tr?.Stocks, index: 3 },
        { list: cryptoListCrypto, label: tr?.Crypto, index: 4 }
    ];

    return(
        <div className="trading_table_wrapper container">
            <div className="table_nav">
                {tables.map((table, idx) => (
                    <div
                        key={idx}
                        className={`${selectedTable === table.list ? 'active' : ''} item`}
                        onClick={() => { 
                            setSelectedTable(table.list); 
                            setSelectedInfo(cryptoListInfo); 
                            setSelectedIndex(table.index); 
                        }}
                    >
                        {table.label}
                    </div>
                ))}
            </div>
            <div className="content_wrapper">
                <div className="table_wrapper">
                    <TradingTable cryptoList={selectedTable.pairs} cryptoListImages={selectedTable.images} cryptoListNames={selectedTable.names} key={selectedTable} />
                    <span className="table_subtext">{tr?.tableSubtext}</span>
                </div>

                <div className={`${selectedInfo === cryptoListInfo ? 'active' : ''} info_wrapper`}>
                        <h3>{cryptoListInfo.title[selectedIndex]}</h3>
                    <p className="subtext">{cryptoListInfo.subtext[selectedIndex]}</p>
                    <p className="text">{cryptoListInfo.text[selectedIndex]}</p>
                    <a className={'main_button small_button'} href={'#form'}>
                        <span>{tr?.mainButtonText}</span>
                    </a>
                </div>
            </div>
        </div>
    )
}