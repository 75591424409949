import { useEffect, useState } from 'react';

async function getFullStocksInfoBySymbol(symbols) {
    const url = encodeURI(`https://yfapi.net/v6/finance/quote?region=US&lang=en&symbols=${symbols.join(',')}`);
    const options = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'X-API-KEY': 'W7Ic2wHED999cefBa6qJ25AH0lpHjqCj8fMUu5fi',
        },
    };
  
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
      return data.quoteResponse.result;
    } catch (error) {
        console.error('There was an error!', error);
        return [];
    }
}

export default function TradingTable({ cryptoList, cryptoListImages, cryptoListNames }) {
    const [cryptoData, setCryptoData] = useState([]);

    const tr = {
        instrumentHeader: 'أداة', //Instrument
        askHeader: 'بسأل', //Ask
        changeHeader: 'تغير', //Change
        percentageHeader: 'تغير', //Percentage
        stepVolumeHeader: '', //Step Volume
        maxLeverageHeader: '', //Max Leverage
        openingHeader: '', //Opening
        closingHeader: '', //Closing
        actionsHeader: '', //Actions
        buyAction: '', //Buy
        sellAction: '' //Sell
    };

    useEffect(() => {
        const fetchCryptoData = () => {
            getFullStocksInfoBySymbol(cryptoList).then(data => {
                setCryptoData(data);
            });
        };
    
        fetchCryptoData();
    }, [cryptoList]);

    return(
        <div className={'table trading_table'}>
            <div className="row table_header">
                <div className="cell">{tr?.instrumentHeader}</div>
                <div className="cell">{tr?.askHeader}</div>
                <div className="cell">{tr?.changeHeader}</div>
                <div className="cell">{tr?.percentageHeader}</div>
            </div>
            <div className="table_body">
                {cryptoData?.map((item, index) => {
                    if (item?.regularMarketPrice != undefined && item?.regularMarketChange != undefined && item?.regularMarketChangePercent != undefined) {
                        return (
                            <div className="row" key={index}>
                                <div className="cell name_cell">
                                    {item?.coinImageUrl ? <img src={item?.coinImageUrl} alt={item?.symbol} /> : <img src={require(`./img/${cryptoListImages[index] || cryptoListImages[0]}`)} />}
                                    <span>{cryptoListNames[index] || item?.symbol?.replace('=X', '')}</span>
                                </div>
                                <div className={`${item?.regularMarketPreviousClose < item?.regularMarketPrice ? 'green' : 'red'} cell price_cell`}>
                                    {item?.regularMarketPreviousClose < item?.regularMarketPrice ? (
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.26526 0.740381C4.07232 0.409629 3.59442 0.409629 3.40148 0.740382L0.772003 5.24807C0.577561 5.58139 0.817998 6 1.20389 6L6.46286 6C6.84875 6 7.08919 5.58139 6.89475 5.24806L4.26526 0.740381Z" fill="#3AA10A" />
                                        </svg>
                                    ) : (
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.76526 5.25962C3.57232 5.59037 3.09442 5.59037 2.90148 5.25962L0.272003 0.751935C0.0775614 0.418607 0.317998 -9.27552e-08 0.703893 -5.90192e-08L5.96286 4.00734e-07C6.34875 4.3447e-07 6.58919 0.418608 6.39475 0.751936L3.76526 5.25962Z" fill="#E44623" />
                                        </svg>
                                    )}
                                    <span>{item?.regularMarketPrice?.toFixed(2)}</span>
                                </div>
                                <div className={`${item?.regularMarketPreviousClose < item?.regularMarketPrice ? 'green' : 'red'} cell price_cell`}>
                                    {item?.regularMarketPreviousClose < item?.regularMarketPrice ? (
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.26526 0.740381C4.07232 0.409629 3.59442 0.409629 3.40148 0.740382L0.772003 5.24807C0.577561 5.58139 0.817998 6 1.20389 6L6.46286 6C6.84875 6 7.08919 5.58139 6.89475 5.24806L4.26526 0.740381Z" fill="#3AA10A" />
                                        </svg>
                                    ) : (
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.76526 5.25962C3.57232 5.59037 3.09442 5.59037 2.90148 5.25962L0.272003 0.751935C0.0775614 0.418607 0.317998 -9.27552e-08 0.703893 -5.90192e-08L5.96286 4.00734e-07C6.34875 4.3447e-07 6.58919 0.418608 6.39475 0.751936L3.76526 5.25962Z" fill="#E44623" />
                                        </svg>
                                    )}
                                    <span>{item?.regularMarketChange?.toFixed(2)}</span>
                                </div>
                                <div className={`${item?.regularMarketPreviousClose < item?.regularMarketPrice ? 'green' : 'red'} cell price_cell`}>
                                    {item?.regularMarketPreviousClose < item?.regularMarketPrice ? (
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.26526 0.740381C4.07232 0.409629 3.59442 0.409629 3.40148 0.740382L0.772003 5.24807C0.577561 5.58139 0.817998 6 1.20389 6L6.46286 6C6.84875 6 7.08919 5.58139 6.89475 5.24806L4.26526 0.740381Z" fill="#3AA10A" />
                                        </svg>
                                    ) : (
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.76526 5.25962C3.57232 5.59037 3.09442 5.59037 2.90148 5.25962L0.272003 0.751935C0.0775614 0.418607 0.317998 -9.27552e-08 0.703893 -5.90192e-08L5.96286 4.00734e-07C6.34875 4.3447e-07 6.58919 0.418608 6.39475 0.751936L3.76526 5.25962Z" fill="#E44623" />
                                        </svg>
                                    )}
                                    <span>{item?.regularMarketChangePercent?.toFixed(2)}%</span>
                                </div>
                            </div>
                        )
                    }
                })
                }
            </div>
        </div>
    )
}